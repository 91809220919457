import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  h5: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    elevation: 2,
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  card: {
    marginTop: theme.spacing(2),
    padding: '0 10px 10px',
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function Contact(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
      <Grid container spacing={3} justify="center" >
          <Grid item xs={12} md={6} alignItems="center">
              <Paper className={classes.card} elevation={5}>
                  <Typography color="inherit" align="center" variant="h6" className={classes.h5}>
                      UK
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Nicola Barker
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Email: <a href="mailto: nicola.barker@webberphillips.com">nicola.barker@webberphillips.com</a>
                  </Typography>
              </Paper>
          </Grid>
          <Grid item xs={12} md={6} alignItems="center">
              <Paper className={classes.card} elevation={5}>
                  <Typography color="inherit" align="center" variant="h6" className={classes.h5}>
                      Europe and International
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Nicola Barker
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Email: <a href="mailto: nicola.barker@webberphillips.com">nicola.barker@webberphillips.com</a>
                  </Typography>
              </Paper>
          </Grid>
          <Grid item xs={12} md={6} alignItems="center">
              <Paper className={classes.card} elevation={5}>
                  <Typography color="inherit" align="center" variant="h6" className={classes.h5}>
                      Canada
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Teresa Sinopoli
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Email: <a href="mailto: Teresa.Sinopoli@environicsanalytics.ca">Teresa.Sinopoli@environicsanalytics.ca</a>
                  </Typography>
              </Paper>
          </Grid>
          <Grid item xs={12} md={6} alignItems="center">
              <Paper className={classes.card} elevation={5}>
                  <Typography color="inherit" align="center" variant="h6" className={classes.h5}>
                      Australia and New Zealand
                  </Typography>
                  <Typography color="inherit" align="left"  className={classes.h5}>
                      Michael Dove
                  </Typography>
                  <Typography color="inherit" align="left" className={classes.h5}>
                      Email: <a href="mailto: michael@originsinfo.com.au">michael@originsinfo.com.au</a>
                  </Typography>
              </Paper>
          </Grid>

      </Grid>
      </Container>
    </section>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
