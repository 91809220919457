import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  faq: {
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  media: {
    width: '100%',
    height: '250px'
  },
  image: {
    width: "100%"
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function FAQDetailed(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
              <Card >
                  <CardMedia image="/gdpr.png" className={classes.media}>
                      </CardMedia>
              </Card>
          </Grid>
          <Grid item xs={12} md={12} style={{"display": "flex", "justifyContent": "center"}}>
              <Typography variant="h4" marked="center" className={classes.title}>
                FAQ
              </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Origins and GDPR
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  You can read more information <a href="/gdpr">here</a>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img
                  src="/team2.png"
                alt="team"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                What information is used to build Origins?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  The Origins classification is built from a global file containing the personal and family names of some 527,000,000 adults from around the world. In addition we have access to personal and family name frequencies covering another 529,000,000 adults. These billion adults are resident in 18 different countries.

                  <br/><br/>
Using this information we have been able to establish the likely Origins code for some 2,000,000 different family names and some 700,000 personal names.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Who Uses Origins and How is it Used?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Origins is used to profile customers and customer segments, citizens and service users, employees and even suppliers. By profiling customers you can identify which groups are under or over-represented on your customer file. You can find out which groups prefer to use which products, channels and outlets, which ones you are good or poor at retaining and which are responsive to which types of promotion or reward.

                  <br/><br/>

Origins is used to code customers. By coding customers you can target campaigns to improve awareness and take up of public services by members of specific minority groups. You can also target products, such as cosmetics, media channels and travel, at audiences for whom they have been especially developed.

                  <br/><br/>

Origins is used to classify postcodes. Using a table which identifies the dominant Origins type in each postcode you can identify and map the locations in which individual communities have established themselves right down to street level.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                  What is Origins coverage rate?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Provided you files free of data capture errors, you should be able to code 99.5% of your customer records by Origins type. The residue are either names which the system does not recognise, because they are rare, or ones which the system can not allocate to any particular Origins type.

              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                What is Origins level of accuracy?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  The level of accuracy varies from one Origins type to another. Origins achieves accuracy rates in excess of 90% in identifying South Asians and Muslims, and 70% in identifying Black Africans, Greeks, Armenians and people from East and South East Europe. It achieves accuracy rates of 50% with Hispanics. Lower accuracy rates are achieved with people of Nordic or French origin, with Jews and Black Caribbeans.

                  <br/><br/>

As would be expected the system is more accurate when coding names to a general categories, such as South Asians or Greeks or Greek Cypriots, than to specific sub-categories, such as Sri Lankans or Greek Cypriots.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                How does Origins handle persons of mixed ancestry?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Origins can be used to identify persons whose names come from more than one tradition – for example a person with an English personal name and a Finnish family name.

                  <br/><br/>

The confidence score given to each name combination can also be used to select or deselect people who are most likely to be of mixed ancestry. Restricting a communication to names with high confidence scores is an effective way of avoiding communicating with individuals who are least likely to belong to the selected target group.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Profiling using Origins
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  When Origins is used to profile customer, citizen or employee files it is possible to compare the distribution of records by Origins on your file with the distribution of the population by Origins in the geographical region which you serve or from which your employees are drawn.
                  <br/><br/>


For example you can specify as your base comparison any administrative region, local authority district, postcode area, police, education or health area in Great Britain. The distribution of the population by Origins is also available for regions of the USA and other European countries.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Using Origins in different countries
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Although Origins is a single application, it has facilities whereby it can be optimised for specific international markets. These international versions code certain names differently in different markets. For example a ‘Roger’, which would be coded as ‘English’ in Britain, would be coded ‘French’ in France. Non GB versions of Origins also allow the mix of names by Origins type to be compared with the Origins mix for the specific market in which the analysis is undertaken.

                  <br/><br/>

The product is particularly attractive to international organisations who need a consistent basis for analysing diversity in each of the national markets in which they operate.

                      <br/><br/>
Output can be configured for local languages and needs. For example the way in which the Origins categories are best grouped will be different in Australia from in the Netherlands. The system provides complete flexibility over classing.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                How is Origins accessed?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Origins types and groups can be appended to customer records using Origins software applications. These applications are licensed to clients by Webber Phillips Ltd or by our partners in North America and in Australia. A PC version is downloadable from the internet and accesses reference files which are updated on a regular basis as names from more countries are introduced to the system.

                  <br/><br/>

The licence fee depends upon the version of the application licensed.  A standard version of the application is designed to code names appearing on British or Irish customer lists.  An enhanced version also appends gender and an estimate of life-stage.  Versions of Origins can also be licensed optimised for different overseas national markets.

                      <br/><br/>
Origins can also be accessed using a web server; or files can be sent directly to Origins Info for coding.

                          <br/><br/>
Some users access Origins at the postcode level, either coding customer by the dominant Origins category in their postcode or using databases showing the mix of Origins types for different levels in the postcode geographic hierarchy.  These databases are updated annually.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

FAQDetailed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQDetailed);
