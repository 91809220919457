import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import Contact from './modules/views/Contact';
import ContactHero from './modules/views/ContactHero';
import Company from './modules/views/Company';
import TeamDetailed from './modules/views/TeamDetailed';
import Clients from './modules/views/Clients';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import AppAppBar from './modules/views/AppAppBar';

function About() {
  return (
    <React.Fragment>
      <AppAppBar />
      <TeamDetailed/>
      <AppFooter />
    </React.Fragment>
  );
}

var AboutRoot = withRoot(About);
export default AboutRoot;
