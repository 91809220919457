import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";

const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: theme.palette.secondary.light,
        overflow: "hidden"
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    item: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(0, 5)
    },
    title: {
        marginBottom: theme.spacing(14)
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    image: {
        height: 150,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    curvyLines: {
        pointerEvents: "none",
        position: "absolute",
        top: -180,
        opacity: 0.7
    },
    animation: {
        width: "50vw",
    },
    button: {
        marginTop: theme.spacing(8)
    }
});

function ProductHowItWorks(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography
                    variant="h4"
                    marked="center"
                    className={classes.title}
                    component="h2"
                >
                    How it works
                </Typography>
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={12}>
                            <img
                                className={classes.animation}
                                src="/origins.gif"
                                alt="software animation"
                            />
                            <ol>
                                <li>Select your compliance requirements.</li>
                                <li>Choose an input data source to process</li>
                                <li>
                                    Tweak operating parameters to your project
                                    requirements.urce to process.
                                </li>
                                <li>Enjoy the results.</li>
                            </ol>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    );
}

ProductHowItWorks.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHowItWorks);
