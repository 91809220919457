import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  gdpr: {
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  media: {
    width: '100%',
    height: '250px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function GDPRDetailed(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
              <Card>
                  <CardMedia image="/gdpr.png" className={classes.media}>
                      </CardMedia>
              </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                GDPR
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Since the introduction of the General Data Protection Regulation Act (GDPR) in May 2018, OriginsInfo and Webber Phillips have received many enquiries from clients asking us for guidance regarding its provisions.
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  It goes without saying that users of the Origins technology need to satisfy themselves that they have adequately considered regulations relating both to:
                  <ul>
                      <li>Personal data</li>
                      <li>Sensitive Personal data</li>
                  </ul>
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Because the requirements of GDPR vary depending both on the status of the organisation and the uses to which data is put we are not in a position to give legal advice. <b> However, in response to the new legislation, we have developed a number of different methods of accessing and using Origins data to enable users to be confident that its use is compliant with the Act.</b>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img
                  src="/team2.png"
                alt="team"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Public Interest Exemption
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  The provisions of the Act include what is referred to as a “public interest exemption”.  This exemption is likely to apply to an organisation which is under a statutory obligation to be cognisant of variations in the use that members of different communities make of its services.  This exemption is relevant to the level of use of services, channels of communication and to outcomes.
                  <br/>
                  <br/>

This statutory obligation is particularly relevant to public sector organisations and is used to justify the appending of Origins to databases of service users.

                  <br/>
                  <br/>

Public Interest Exemption is also relevant in so far as there is a statutory requirement to monitor the level of diversity of senior employees and to introduce policies to improve levels of diversity in the workforce.

                  <br/>
                  <br/>

This use of Public Interest Exemption does not relax obligations regarding the security of the data supplied using Origins.  Standard practice is for our public-sector users to restrict access to any records that have been coded by Origins to members of their research and analysis teams.   In most cases the data are held in a physically secure environment with restricted access.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Using Origins data at the postcode or higher level
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  Among organisations that do not meet the criteria that entitle them to claim public interest exemption, an increasingly common practice is to access Origins data using the postcode of the data subject rather than his or her personal and family name.

                  In this case Origins is used in a manner analogous to the postcode versions of Acorn and Mosaic.  Using the postcode of the data subject Origins technology will:
                  <ul>
                      <li>Flag whether, for each data subject, there are a sufficient number of adult names in his or her postcode for it to be possible to append to his or her record information on the cultural make-up of the postcode without compromising the personal data pertaining to any adult living there.</li>
                      <li>If it is to identify whether the postcode has a significant non-white British population or whether it is almost exclusively white British.</li>
                      <li>In the event that the non-white British population is significant, indicate which is the dominant minority grouping, subject to a frequency threshold.</li>
                  </ul>

                  The coding of files using Origins at postcode level offers a number of benefits other than merely in relation to GDPR
                  <ul>
                      <li>The behaviour of members of minority communities does vary according to the type of postcode in which they live.  Those living in postcodes where their community is the largest group tend to be the ones who are the least integrated and the most difficult to reach and are more likely to exhibit the behaviours characteristic of their group than to members of the same community living in predominantly white British postcodes.</li>
                      <li>Other adults living in postcodes which are predominantly non-white British tend to differ from their counterparts in predominantly white British neighbourhoods whatever community they belong to.</li>
                  </ul>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Restricting output to distributions by Origins category
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  There are a few instances where users append Origins codes to the information they hold about a data subject in order to vary the communications they send to particular customers of service users.  But the majority use Origins in order to undertake statistical analysis to explore differences in the behaviour of different groups.

                  <br/><br/>

In earlier versions of the Origins software the default output was a file in which input records were enriched with data fields based on the personal and family names of data subjects.  It was assumed that users would then use this output file to undertake statistical analysis.

                  <br/><br/>
As a result of the introduction of GDPR the Origins software has been amended so that it is now possible to access a version whose output is restricted to the supply of statistical data only.  By comparing this distribution with the frequency distributions of adults in different geographical areas, it is possible to identify Origins categories which are over- or under-represented on the input file but without identifying which input records (or data subjects) belong to which group.

                  <br/><br/>

Since no external Origins data is attributed to any identifiable individual this form of analysis falls entirely outside the provisions of the Act.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Suppression of names from output records
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  To respond to the stipulations of GDPR, Origins Info and Webber Phillips has also introduced a facility to the standard version of its software whereby the names and postcodes that are contained on the input file can be suppressed from the output file on which they would otherwise have been included.

                  <br/><br/>

Using this facility, the user inputs a file containing the name and, if appropriate, the ID and postcode of each data subject.  The name is used to generate an Origins code.  However, the application suppresses the two name fields (personal and family name) from the output file so that no sensitive personal information is linked to the identity of the data subject.

                  <br/><br/>

This method meets the needs of users wishing to undertake statistical analysis using an anonymised file of individual level records.

If an ID is included in the input file, the privacy of the data subject is secured by the name (or name and address) and the Origins code never being contained on the same file.

                  <br/><br/>

This approach is very similar to standard practice whereby market research agencies keep confidential the identity of respondents to market surveys.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

GDPRDetailed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GDPRDetailed);
