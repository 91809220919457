import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "../components/Button";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    paddingTop: "20px",
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
});

function Team(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography
                variant="h4"
                marked="center"
                className={classes.title}
              >
                Our Team
              </Typography>
              <Typography variant="h5">
                {
                  "Between them, our co-founders have 30+ years' experience delivering consumer and technical solutions for well-known organisations such as Microsoft and Yale University."
                }
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img src="/team2.png" alt="team" className={classes.image} />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            md={12}
            justify="right"
            style={{ "text-align": "left", "margin-left": "40px" }}
          >
            <Button
              color="secondary"
              variant="contained"
              size="large"
              className={classes.button}
              component="a"
              href="/team"
            >
              More...
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Team.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Team);
