import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ProductHero from './modules/views/ProductHero';
import Contact from './modules/views/Contact';
import ContactHero from './modules/views/ContactHero';
import Company from './modules/views/Company';
import Team from './modules/views/Team';
import Clients from './modules/views/Clients';
import ProductHowItWorks from './modules/views/ProductHowItWorks';
import AppAppBar from './modules/views/AppAppBar';

function Home() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <Company/>
      <Team/>
      <ProductHowItWorks />
      <Clients/>
      <ContactHero/>
      <Contact/>
      <AppFooter />
    </React.Fragment>
  );
}

var HomeRoot = withRoot(Home);
export default HomeRoot;
