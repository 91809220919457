import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function TeamDetailed(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Origins Info
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'OriginsInfo Ltd was founded in 2004.  Its joint managing directors are Richard Webber, originator of the Acorn and Mosaic postcode classifications systems, and Andrew Webber, a former Microsoft employee.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'The company’s mission is to improve organisational awareness of how differently their products and services are used by people from different cultural backgrounds.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Central to our technology is Origins.  This database associates some 4 million surnames and some 800,000 personal names with their ethnic, religious and linguistic background. '}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Users match customer files against this database to identify groups that are over- or under-represented compared with the size of that community nationally or locally.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img
                  src="/team2.png"
                alt="team"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                The Challenge
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'In an increasingly diverse world, organisations, both public and private, are understandably concerned to ensure they engage effectively with members of different minority communities, whether as customers, service users, employees of suppliers.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Their motivations may be compliant, a sense of corporate responsibility or purely commercial – to ensure they do not miss out on the benefits of the skills and spending power of different minority groups.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Historically such concerns have been reflected in well intended policies, the impact of which it has unfortunately been impossible to monitor.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'The mission of OriginsInfo is to provide organisations with the ability to infer a person’s cultural identity from his or her name and therefore with the ability to track in detail and in real time differences in the manner in which different communities engage with them.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Distribution
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'OriginsInfo.Ltd licences different distributors for different markets.  Our UK distributor is Webber Phillips.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Other distributors service North America and Australia. Contact us to find out more.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Our Team
              </Typography>
              <Grid container>
                  <Grid item xs={12} md={6}>
                      <Grid container>
                          <img item src="/richard.png" alt="richard webber" xs={4}/>
                            <Typography item variant="h6" xs={4} >
                                Richard Webber, Managing Director
                            </Typography>
                            <Typography item variant="body2" xs={4} className={classes.text}>
                                Richard is the originator of the postcode classification systems Acorn and Mosaic, and is a former Director of Experian.  He is a visiting professor at the University of Newcastle and a fellow of the Market Research Society and the Institute of Direct Marketing. Richard leads on analysis for Origins Info.
                            </Typography>
                      </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <Grid container>
                          <img item src="/andrew.png" alt="richard webber" xs={4}/>
                            <Typography item variant="h6" xs={4} >
                                Andrew Webber, Managing Director
                            </Typography>
                            <Typography item variant="body2" xs={4} className={classes.text}>
                                CTO and Unicorn Engineer; Cloud, Mobile, Web and Desktop
                            </Typography>
                            <Typography item variant="body2" xs={4} className={classes.text}>
                                <a href="https://github.com/andrewwebber">https://github.com/andrewwebber</a>
                            </Typography>
                            <Typography item variant="body2" xs={4} className={classes.text}>
                                <a href="https://twitter.com/andrewvwebber">@andrewvwebber</a>
                            </Typography>
                      </Grid>
                  </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

TeamDetailed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamDetailed);
