import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Container from '@material-ui/core/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  button: {
    maxWidth: 200,
    textAlign: "end"
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  image: {
    width: 300,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

const tileData = [
    {
        img: "/premier-league.png",
        title: "Premier League"
    },
    {
        img: "/curve.png",
        title: "Curve"
    },
    {
        img: "/yale.png",
        title: "Yale"
    },
    /* {
        img: "/blexley.png",
        title: "Blexley"
    },
    {
        img: "/directors.png",
        title: "Directors UK"
    }*/
];

function Clients(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} justify="center" direction="row">
            <Grid item xs={12} justify="center">
              <Typography variant="h4" marked="center" className={classes.title}>
                Clients
              </Typography>
            </Grid>
            <Grid item xs={12}>
                <GridList className={classes.gridList} cols={2.5}>
                    {tileData.map(tile => (
                      <GridListTile key={tile.img}>
                        <img src={tile.img} alt={tile.title} className={classes.image} />
                      </GridListTile>
                    ))}
                  </GridList>
            </Grid>
              <Button
                xs={12}
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="/services"
              >
                More...
              </Button>
         </Grid>
      </Container>
    </section>
  );
}

Clients.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Clients);
