import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ContactDetailed from './modules/views/ContactDetailed';
import AppAppBar from './modules/views/AppAppBar';

function Contact() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ContactDetailed/>
      <AppFooter />
    </React.Fragment>
  );
}

var ContactRoot = withRoot(Contact);
export default ContactRoot;
