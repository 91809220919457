import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import FAQDetailed from './modules/views/FAQDetailed';
import AppAppBar from './modules/views/AppAppBar';

function FAQ() {
  return (
    <React.Fragment>
      <AppAppBar />
      <FAQDetailed/>
      <AppFooter />
    </React.Fragment>
  );
}

var FAQRoot = withRoot(FAQ);
export default FAQRoot;
