import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Contact from './Contact';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  services: {
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  media: {
    width: '100%',
    height: '250px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ContactDetails(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
              <Card>
                  <CardMedia image="/services.png" className={classes.media}>
                 </CardMedia>
                 <CardContent>
                   <Grid item xs={12} md={12} style={{"display": "flex", "justifyContent": "center"}}>
                       <Typography variant="h4" marked="center" className={classes.title}>
                         Contact Us
                       </Typography>
                   </Grid>
                   <Contact />
                 </CardContent>
              </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ContactDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactDetails);
