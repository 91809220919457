import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ProductHowItWorks from './ProductHowItWorks';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  services: {
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    justifyContent: 'center'
  },
  media: {
    width: '100%',
    height: '250px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ServicesDetailed(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
              <Card>
                  <CardMedia image="/services.png" className={classes.media}>
                 </CardMedia>
              </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Our Services
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'OriginsInfo has created a suite of product and services which enable an organisation to better understand the cultural, ethnic and linguistic origins of its users, customers, clients and employees.'}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                  <ul>
                      <li>Desktop Workstation Application</li>
                      <li>Real Time API</li>
                      <li>Scheduled Batch Jobs</li>
                  </ul>
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'At the heart of these products and services lies the Origins classification system.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img
                  src="/data2.png"
                alt="team"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Who uses OriginsInfo technology?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'When an organisation chooses to use OriginsInfo technology it is because it is looking for hard evidence of how the take up of their products and services differs from one minority group to another.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                Why do they use OriginsInfo technology to do this?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'Organisations use OriginsInfo technology because its provides more comprehensive, granular and up to date information on ethnicity than can be obtained from other sources such as survey questionnaires or the 2011 census.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title}>
                How does OriginsInfo technology deliver these insights?
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {'OriginsInfo delivers these insights using a proprietary algorithm which compares the personal and family names on a user’s administrative file with the ethnic, religious and cultural origin of 5,000,000 personal and family names from around the world.'}
              </Typography>
            </div>
          </Grid>
      <ProductHowItWorks />
        </Grid>
      </Container>
    </section>
  );
}

ServicesDetailed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ServicesDetailed);
