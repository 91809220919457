import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '../components/Button';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    textAlignt: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    paddingTop: '20px'
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function Company(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container} >
        <Grid container spacing={5}>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <img
                  src="/data2.png"
                  alt="team"
                  className={classes.image}
              />
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className={classes.item}>
              <Typography variant="h4" marked="center" className={classes.title} component="h2">
                Services
              </Typography>
              <Typography variant="h5">
                {'OriginsInfo is a consumer insights consultancy and analytics tool enabling applications and businesses to acquire valuable customer insight purely from a first or last name.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={12} justify="right" style={{"text-align":"end"}}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="/services"
              >
                More...
              </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Company.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Company);
