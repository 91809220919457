import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import ServicesDetailed from './modules/views/ServicesDetailed';
import AppAppBar from './modules/views/AppAppBar';

function Services() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ServicesDetailed/>
      <AppFooter />
    </React.Fragment>
  );
}

var ServicesRoot = withRoot(Services);
export default ServicesRoot;
