import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import HomeRoot from "./containers/home";
import TeamRoot from "./containers/team";
import GDPRRoot from "./containers/gdpr";
import ContactRoot from "./containers/contact";
import FAQRoot from "./containers/faq";
import ServicesRoot from "./containers/services";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <HomeRoot />
          </Route>
          <Route path="/team">
            <TeamRoot />
          </Route>
          <Route path="/services">
            <ServicesRoot />
          </Route>
          <Route path="/gdpr">
            <GDPRRoot />
          </Route>
          <Route path="/contact">
            <ContactRoot />
          </Route>
          <Route path="/faq">
            <FAQRoot />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
