import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from './modules/views/AppFooter';
import GDPRDetailed from './modules/views/GDPRDetailed';
import AppAppBar from './modules/views/AppAppBar';

function GDPR() {
  return (
    <React.Fragment>
      <AppAppBar />
      <GDPRDetailed/>
      <AppFooter />
    </React.Fragment>
  );
}

var GDPRRoot = withRoot(GDPR);
export default GDPRRoot;
